.boxLogin{
    /* border: 1px solid red; */
    display: flex;
    width: 100%;
    height: 93vh;
}

.boxForm{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.form{
    display: flex;
    flex-direction: column;
}

.divCheck{
    display: flex;
    justify-content: center;
    align-items: center;
}

.span{
    margin-right: 20px;
    margin-left: -8px;
}

.boxImage{
    width: 50%;
}
.imgEngranes{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.logo{
    width: 200px;
}

@media (max-width: 1024px) {
    .logo{
        width: 150px;
        margin-bottom: 20px;
    }
  }

@media (max-width: 950px) {
    .boxImage {
        display: none;
    }
}

@media (max-width: 500px) {
    .boxLogin{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height:90vh;
    }
}