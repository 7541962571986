.welcomeUser{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 50px;
    height: 80px;
}
.boxLogoSearch{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    height: 100px;
    padding: 0;
}
.logoSearch{
    width: 200px;
}
.boxSearch{
    margin-top: 40px;
    margin-bottom:20px ;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.boxCards{
    width: 90%;
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
}
.boxInterno{
    width: 90%;
    display: flex;
    /* border: 1px solid red; */
    flex-wrap: wrap;
    /* align-items: center; */
    justify-content: center;
    margin-top: 20px;
}

@media (max-width: 1024px) {
    .welcomeUser{
        margin-top: 50px;
    }
  }
