.boxCards{
    /* border: 1px solid red; */
    border-radius: 5px;
    width: 310px;
    height: 230px;
    background-color: #F1F4F6;
    margin: 15px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.contentCard{
    margin: 5px;
}
.contentCard p{
    font-size: 12px;
}

.contentCard p span {
    font-size: 10px;
}