.boxLogin{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
}
.boxForm{
    /* border: 1px solid red; */
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.form{
    display: flex;
    flex-direction: column;
}

.divCheck{
    display: flex;
    justify-content: center;
    align-items: center;
}

.span{
    margin-right: 20px;
    margin-left: -8px;
}

.boxImage{
    /* border: 1px solid red; */
    width: 50%;
}
.imgEngranes{
    width: 100%;
    height: 90vh;
}