.principalBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.boxLogo{
    margin-top: 20px;
}

.h3{
    margin-top: 20px;
    width: 100%;
    text-align: center;
    font-size: 30px;
}
.boxClientes{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-direction: column;
}

ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
li{
    margin-right: 20px;
}

.imgNosotros{
    width: 100%; 
    height: 120px;
}

h5{
    text-align: center;
}
.imgMarcas{
    width: 80%;
}




