.welcomeUser{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 50px;
    height: 80px;
}
.emtyShop{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50vh;
}