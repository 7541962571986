.principalBox{
    /* border: 1px solid red; */
    height: 79px;
    background-color: #0A2B6B;
    
}

.menuBox {
    display: flex;
    width: 100%;
    height: 100%;
}

.menuBox ul {
    /* border: 1px solid red; */
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.links {
    margin-right: 15px;
    color: #C6F40A;
    text-decoration: none;
    list-style: none;
}

.logo{
    margin-left: 50px;
    height: 80px;
}

