.boxTable{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.emtyShop{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50vh;
}
.h4{
    /* border: 1px solid red; */
    text-align: center;
}
