.boxAdmins{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
}

@media (max-width: 1024px) {

    .titleAdmin{
        font-size: 20px;
    }
    }