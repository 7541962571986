
h3{
    font-size: 15px;
    width: 600px;
    text-align: justify;
}
.boxContacto{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93vh;
    flex-direction: column;
    /* border: 1px solid red; */
}

.formulario{
    /* border: 1px solid red; */
    width: 30%;
}

.textField{
    width: 100%;
}

@media (max-width: 1024px) {

    .logoContacto{
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

@media (max-width: 960px) {
    .logoContacto{
        margin-bottom: 30px;
    }

  .boxContacto{
    height: 120vh;
  }
}